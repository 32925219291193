import {Component, Inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from './shared/services/storage.service';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import {GoogleAnalyticsService} from './shared/services/google-analytics.service';
import {environment} from '../environments/environment';
import {Title} from '@angular/platform-browser';
import { DNSService } from './shared/services/dns.service';
import { Utils } from './shared/utils';
import { DOCUMENT } from '@angular/common';
import { User } from './shared/models/user';
import { LoaderService } from './shared/services/loader.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Eventizer Abstract';
  direction
  user: User = new User();
  navigationEnd;
  rouePathParam;
  routePathParam
  constructor(translate: TranslateService,
   private  storageService: StorageService,
    public router: Router,
    private titleService: Title, 
    private route: ActivatedRoute,
    private dnsService: DNSService,
    @Inject(DOCUMENT) private document: Document,
    private loaderService: LoaderService 
  ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loaderService.startLoading();
      } else if (event instanceof NavigationEnd) {
        this.loaderService.stopLoading();
      }
    });
    GoogleAnalyticsService.loadGoogleAnalytics(environment.gaTrackingID);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.gaTrackingID,
          {
            'page_path': event.urlAfterRedirects
          }
        );
        GoogleAnalyticsService.eventEmitter(event.urlAfterRedirects);
      }
    });
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const lang = storageService.read<string>('lang');
    translate.use(lang ? lang : 'fr');
    const url = window.location.href.toLowerCase();
    this.direction=Utils.getDirectiontemplate(lang)
    translate.onLangChange.subscribe((event: any) => {
      this.direction=Utils.getDirectiontemplate(event.lang)
    })
    if (!url.includes(environment.url)) {
      this.dnsService.getDNSByUrl(url).subscribe((data: any) => {
        if (data.redirect) {
          const newUrl = data.redirect.replace(':congressId', data.congress_id);
            // extract param from the url
            const queryParams = {};
            const queryString = newUrl.split('?')[1];
            if (queryString) {
              queryString.split('&').forEach(param => {
                const parts = param.split('=');
                queryParams[parts[0]] = decodeURIComponent(parts[1]);
              });
            }
            // redirect to the url after insering params if they exist
            queryString ? this.router.navigate([newUrl.slice(0, newUrl.indexOf('?'))], { queryParams: queryParams}) : this.router.navigate([newUrl]);
        }
      });
    }

    this.titleService.setTitle("Formulaire d'inscription");
  }

}
