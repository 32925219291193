import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericService} from './generic.service';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Service} from '../models/assignment_service';
import {Establishment} from '../models/establishment';
import { Country } from '../models/Country';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getAllCountries(congressId = null){
    const url = environment.baseUrl + "/countries?congressId=" + congressId;
    return this.http.get<Array<Country>>(url)
      .pipe(catchError(this.handleErrors));
  }

  uploadFile(file: File) {
    const data: FormData = new FormData();
    data.append('files', file, file.name);
    return this.http.post<any>(environment.baseUrl + '/files/upload-resource', data);
  }

  
  convertCurrencyRates(convertFrom, convertTo) {
    const url = environment.baseUrl + '/currency/convert-rates?convertFrom=' + convertFrom + '&convertTo=' + convertTo;
    return this.http.get(url);
  }

  getEtablissementByCongressId(congressId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + '/etablissements/all';
    return this.http.get<Establishment[]>(url,{ headers: headers });
  }

  getEtablissementByCongressIdPublic(congressId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + '/etablissements/all/public';
    return this.http.get<Establishment[]>(url, { headers: headers });
  }

  getServiceByCongressId(congressId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + '/services/all';
    return this.http.get<Service[]>(url,{ headers: headers });
  }

  getServiceByCongressIdPublic(congressId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + '/services/all/public';
    return this.http.get<Service[]>(url, { headers: headers });
  }
}
