<div class="inner-box ">
    <div class="list-box">
      <div class="scroll-submission" infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()">
    <div class="sub">
      <h2 class="main-title"> {{'HOME.MY_CONTACTS' | translate}} </h2>
      <input  class="search-bar form-control"  (ngModel)="search" type="text" placeholder="{{'HOME.SEARCH'|translate}}" (keyup.enter)="filter()">
  
    </div>
  <div  class="form-group" >
    <h4 >{{'SHARED.FILTERS' | translate}}</h4>
      <div class="dropdown btn-group">
          <div class="pull-left ">
            <div class="input-group">
  
              <div class="input-group-append">
                <select style="width:100%" class="list-group-item  form-control select select-congress"
              name="select-congress"
              aria-hidden="true"
              (click)="selectCongress($event.target.value)"
             >
       
        <option  *ngFor="let congress of congresses"
                value="{{congress.congress_id}}"
              
                > {{congress.name}}
        </option>
      </select>
  
              </div>
          </div>
      </div>
      </div>
    
      <div class=" btn-group" style="margin-left:45px">
        <button (click)="filter()" style="background-color:#4563ac; color:white " class="btn btn-light " >
          <i class="icon-filter"></i> {{'Filter' | translate}}      </button>
  
      </div>

      <div  ngBusy="busy"
      class="adds-wrapper jobs-list ">
   <div class="item-list job-item submission " *ngFor="let contact of contacts; let i= index" >
     
    
    
  



     
     <div class="submission-details">

      <div class="speaker-container">
        <div class="speaker-img">
          <img
            class="img-responsive speaker-img"
            [src]="imgUrl+'6OKCh49z9mlSYRFDnMewrhzqEjLsjlwdlYkvdoct.jpg'"
            alt="img"
          />
        </div>
    </div>

        <div class="subb">
         <div class="submission-header">   
          
          <div class="submission-title " >
            {{contact.first_name}} {{contact.last_name}}
          </div>

           <h5 class="company-title ">
            <div aria-label="My icon"
            style="margin-left: 3px; margin-top: 10px ;cursor: pointer;"
            (click)="deleteContact(contact.user_viewed,contact.congress_id,i)"
            [src]="imgUrl+'FsJUokAwHOIPqdnj3p8lyEhmVmSoGCvKhTjnsMTN.svg'"
            [setSVGAttributes]="{'width':'22px', 'height':'20px'}"></div>
            </h5>

        </div>
      

        <a class="congress-title "[routerLink]="['/congress/details/'+ contact.congress_id] ">
                <span> @{{contact?.congress?.name  ? contact?.congress?.name : ('HOME.HORS_EVENT' | translate) }}  </span><div *ngIf="contact?.congress?.name"class="event-arrow position-relative" aria-label="My icon"
                      [src]="imgUrl + 'r8FggJ3ZC4s86ZG8TR73hrrl6qQBZ90ujDBck7To.svg'"
                      [setSVGAttributes]="{'width':'12px', 'height':'12px'}">
                </div>
   
        </a>

        </div>


    

     </div>






    </div>
      </div>
      </div>
      </div>
      </div>
      </div>


