import {Establishment} from './establishment';
import {Service} from './assignment_service';
import {Access} from './Access';
import {Payment} from './Payment';
import { Country } from './Country';
import { UserCongress } from './UserCongress';
import { Resource } from './Resource';
import { Response } from './response';
import { Pack } from './Pack';

// import {Country} from "./Country";

export class User {
  active: boolean;
  authorities: string[];
  accesses: Access[];
  user_id: number;
  last_name: string;
  first_name: string;
  speciality: string;
  email: string;
  code: string;
  mobile: string;
  user_congresses: UserCongress[] = [];
  country_id: string;
  country:Country;
  chatInfoArray: string[] = [];
  responses: any[] = [];
  accessIds: number[] = [];

  /* supplement */
  adress: string;
  city: string;
  secteur: string;
  grade: string;
  establishment: Establishment = new Establishment();
  establishmentId: string;
  service: Service = new Service();
  serviceId: string;
  id: string;
  password: string;
  // country: Country = new Country();
  gender: number;
  payments: Payment[] = [];
  profile_pic: string;
  profile_img: Resource;
  resource_id: number;
  passwordConfirm: string;
  privilege_id: number;
  packIds: number[] = [];
  price: number = 0;
  codePromo: string;
  lang_preference: any;
  cachedId: string;
  packs: Pack[] = [];
  discountId: number;
  promos: any [] = [];
  verification_code: string;
}

export class User_Congress {
  user_congress_id: number;
  congress_id: number;
  user_id: number;
  privilege_id: number;
  chat_info :string;
  fix_table_info: string;
}
