import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLandingPageComponent } from './auth-landing-page/auth-landing-page.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';

import { LandingPageEventComponent } from './landing-page-event/landing-page-event.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { WaitingComponent } from './waiting/waiting.component';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';



const routes: Routes = [
  {
    path: 'landingpage/:id_congress',

    component: LandingPageEventComponent
  },
  {
    path: 'landingpage/:id_congress/login',

    component: AuthLandingPageComponent
  },
  {
    path: 'password/reset/congress/:id_congress',
    component: ForgetPasswordComponent
  },
  {
    path: 'password/reset/:id_congress/:userId',
    component: ResetPasswordComponent
  },
  {
    path: '',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'landingpage/:id_congress/waiting-room',
    component: WaitingComponent
  },
  {
    path: 'landingpage/:userId/thank-you/:congressId',
    component: ThankYouPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingPageEventRoutingModule { }
