<section id="home" class="bg-half-170 bg-light d-table w-100" style="padding: 60px 0px;">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Conditions et termes </h4>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white"><svg viewBox="0 0 2880 48" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor">
      </path>
    </svg></div>
</div>
<section class="section" style="background-color: white;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <p class="text-muted">La présente Politique de confidentialité décrit Nos politiques et procédures en
              matière de collecte, d’utilisation et de divulgation de Vos informations lorsque Vous utilisez le Service
              et Vous informe de Vos droits en matière de confidentialité et de la manière dont la loi Vous protège.
              Nous utilisons Vos données personnelles pour fournir et améliorer le Service. En utilisant le Service,
              vous acceptez la collecte et l’utilisation des informations conformément à la présente Politique de
              confidentialité.
            </p>
            <h4 class="card-title">Interprétation et définitions</h4>
            <h5 class="card-title">Interprétation</h5>
            <p class="text-muted">Les mots dont la lettre initiale est en majuscule ont des significations définies dans
              les conditions suivantes. Les définitions suivantes ont le même sens, qu’elles apparaissent au singulier
              ou au pluriel.
            </p>
            <h5 class="card-title">Définitions</h5>
            <p class="text-muted">Aux fins de la présente politique de confidentialité :
            </p>
            <ul class="list-unstyled text-muted ml-4">
              <li>
                Compte désigne un compte unique créé pour Vous afin d’accéder à notre Service ou à certaines parties de
                notre Service

              </li>
              <li>
                Société (désignée par ” la Société “, ” Nous “, ” Notre ” ou ” Nos ” dans le présent Accord) désigne
                Eventizer, Tunis
              </li>
              <li>
                Appareil désigne tout appareil permettant d’accéder au Service tel qu’un ordinateur, un téléphone
                portable ou une tablette numérique.
              </li>
              <li>
                Données Personnelles désigne toute information qui se rapporte à un individu identifié ou identifiable.
              </li>
              <li>Service désigne l’Application ou le Site Web eventizer. </li>
              <li> Prestataire désigne toute personne physique ou morale qui traite les données pour le compte de la
                Société. Il désigne les sociétés tierces ou les personnes employées par la Société pour faciliter le
                Service, pour fournir le Service au nom de la Société, pour effectuer des services liés au Service ou
                pour aider la Société à analyser la façon dont le Service est utilisé.
              </li>
              <li> Service de médias sociaux tiers désigne tout site Web ou tout site Web de réseau social par le biais
                duquel un Utilisateur peut se connecter ou créer un compte pour utiliser le Service.
              </li>
              <li> Données d’utilisation désignent les données collectées automatiquement, soit générées par
                l’utilisation du Service, soit provenant de l’infrastructure du Service elle-même (par exemple, la durée
                de visite d’une page).
              </li>
              <li>Vous désigne la personne qui accède au Service ou l’utilise, ou la société ou autre entité juridique
                au nom de laquelle cette personne accède au Service ou l’utilise, selon le cas.
              </li>
            </ul>
            <h4 class="card-title mt-2">Collecte et utilisation de vos données personnelles</h4>
            <h5 class="card-title">Types de données collectées</h5>
            <h6 class="card-title">Données personnelles</h6>
            <p class="text-muted">Lors de l’utilisation de notre Service, nous pouvons vous demander de nous fournir
              certaines informations personnellement identifiables qui peuvent être utilisées pour vous contacter ou
              vous identifier. Les informations personnellement identifiables peuvent inclure, mais ne sont pas limitées
              à :</p>
            <ul class="list-unstyled text-muted ml-4">
              <li>
                Adresse électronique
              </li>
              <li>
                Nom et prénom
              </li>
              <li>
                Numéro de téléphone
              </li>
              <li>
                Addresse postale
              </li>
              <li>
                Données d’utilisation
              </li>
            </ul>
            <h6 class="card-title mt-2">Usage Data </h6>
            <p class="text-muted">Les Données d’utilisation sont collectées automatiquement lors de l’utilisation du
              Service.</p>
            <p class="text-muted">Les Données d’utilisation peuvent inclure des informations telles que l’adresse de
              protocole Internet (par exemple, l’adresse IP) de votre appareil, le type de navigateur, la version du
              navigateur, les pages de notre Service que vous visitez, l’heure et la date de votre visite, le temps
              passé sur ces pages, les identifiants uniques des appareils et d’autres données de diagnostic.
            </p>
            <p class="text-muted">Lorsque vous accédez au Service par ou via un appareil mobile, nous pouvons recueillir
              automatiquement certaines informations, y compris, mais sans s’y limiter, le type d’appareil mobile que
              vous utilisez, l’identifiant unique de votre appareil mobile, l’adresse IP de votre appareil mobile, votre
              système d’exploitation mobile, le type de navigateur Internet mobile que vous utilisez, les identifiants
              uniques des appareils et d’autres données de diagnostic.
            </p>
            <p class="text-muted">Nous pouvons également collecter les informations que votre navigateur envoie chaque
              fois que vous visitez notre Service ou lorsque vous accédez au Service par ou via un appareil mobile.
            </p>

            <h6 class="card-title">Informations collectées lors de l’utilisation de l’application</h6>
            <p class="text-muted">Lors de l’utilisation de notre Application, afin de fournir des fonctionnalités de
              notre Application, nous pouvons collecter, avec votre autorisation préalable :
            </p>
            <ul class="list-unstyled text-muted ml-4">
              <li>
                Informations concernant votre localisation
              </li>
              <li>
                Informations provenant de l’annuaire téléphonique de votre appareil (Pour afficher les contacts ayant un
                compte eventizer)
              </li>
              <li>
                Images et autres informations provenant de l’appareil photo et de la bibliothèque de photos de votre
                appareil.
              </li>
            </ul>
            <p class="text-muted">Nous utilisons ces informations pour fournir des fonctionnalités de Notre Service,
              pour améliorer et personnaliser Notre Service. Les informations peuvent être téléchargées sur les serveurs
              de la Société et/ou sur le serveur d’un fournisseur de services ou elles peuvent être simplement stockées
              sur votre appareil.
              Vous pouvez activer ou désactiver l’accès à ces informations à tout moment, via les paramètres de Votre
              appareil.
            </p>
            <h5 class="card-title">Utilisation de vos données personnelles</h5>
            <p class="text-muted">La Société peut utiliser les Données personnelles aux fins suivantes :
            </p>
            <ul class="list-unstyled text-muted ml-4">
              <li>
                Pour fournir et maintenir notre Service, y compris pour surveiller l’utilisation de notre Service.
              </li>
              <li>
                Pour gérer votre compte : pour gérer votre inscription en tant qu’utilisateur du Service. Les Données
                personnelles que vous fournissez peuvent vous donner accès à différentes fonctionnalités du Service qui
                vous sont accessibles en tant qu’utilisateur enregistré.
              </li>
              <li>
                Pour l’exécution d’un contrat : l’élaboration, le respect et l’engagement du contrat d’achat des
                produits, articles ou services que Vous avez achetés ou de tout autre contrat avec Nous par le biais du
                Service.
              </li>
              <li>
                Pour vous contacter : Vous contacter par email, appels téléphoniques, SMS, ou autres formes équivalentes
                de communication électronique, telles que les notifications push d’une application mobile concernant les
                mises à jour ou les communications informatives liées aux fonctionnalités, produits ou services
                contractés, y compris les mises à jour de sécurité, lorsque cela est nécessaire ou raisonnable pour leur
                mise en œuvre.
              </li>
              <li>
                Pour vous fournir des nouvelles, des offres spéciales et des informations générales sur d’autres biens,
                services et événements que nous proposons et qui sont similaires à ceux que vous avez déjà achetés ou
                sur lesquels vous vous êtes renseigné, sauf si vous avez choisi de ne pas recevoir ces informations.
              </li>
              <li>
                Pour gérer vos demandes : Pour répondre et gérer les demandes que vous nous adressez.
              </li>
              <li>
                Pour les transferts d’entreprise : Nous pouvons utiliser Vos informations pour évaluer ou mener une
                fusion, un désinvestissement, une restructuration, une réorganisation, une dissolution ou toute autre
                vente ou transfert de tout ou partie de Nos actifs, que ce soit dans le cadre d’une poursuite d’activité
                ou d’une faillite, d’une liquidation ou d’une procédure similaire, dans laquelle les Données
                personnelles que Nous détenons sur les utilisateurs de nos Services font partie des actifs transférés.
              </li>
              <li>
                À d’autres fins : Nous pouvons utiliser Vos informations à d’autres fins, telles que l’analyse des
                données, l’identification des tendances d’utilisation, la détermination de l’efficacité de nos campagnes
                promotionnelles et l’évaluation et l’amélioration de notre Service, de nos produits, de nos services, de
                notre marketing et de votre expérience.
              </li>
            </ul>
            <p class="text-muted">Nous pouvons partager Vos informations personnelles dans les situations suivantes :
            </p>
            <ul class="list-unstyled text-muted ml-4">
              <li>
                Avec les fournisseurs de services : Nous pouvons partager vos informations personnelles avec les
                fournisseurs de services pour surveiller et analyser l’utilisation de notre service, pour vous
                contacter.
              </li>
              <li>
                Avec d’autres utilisateurs : lorsque Vous partagez des informations personnelles ou interagissez de
                toute autre manière dans les zones publiques avec d’autres utilisateurs, ces informations peuvent être
                vues par tous les utilisateurs et peuvent être diffusées publiquement à l’extérieur. Si vous
                interagissez avec d’autres utilisateurs ou si vous vous inscrivez par le biais d’un service de médias
                sociaux tiers, vos contacts sur le service de médias sociaux tiers peuvent voir votre nom, votre profil,
                vos photos et la description de votre activité. De même, d’autres utilisateurs pourront voir les
                descriptions de votre activité, communiquer avec vous et voir votre profil.
              </li>
              <li>
                Avec votre consentement : Nous pouvons divulguer Vos données personnelles à toute autre fin avec Votre
                consentement.
              </li>
            </ul>
            <h5 class="card-title mt-2">Conservation de vos données personnelles </h5>
            <p class="text-muted">La Société ne conservera vos Données personnelles que pendant la durée nécessaire aux
              fins énoncées dans la présente Politique de confidentialité. Nous conserverons et utiliserons Vos Données
              personnelles dans la mesure nécessaire pour nous conformer à nos obligations légales (par exemple, si nous
              sommes tenus de conserver vos données pour nous conformer aux lois applicables), résoudre les litiges et
              appliquer nos accords et politiques juridiques.
            </p>
            <p class="text-muted">La Société conservera également les Données d’utilisation à des fins d’analyse
              interne. Les Données d’utilisation sont généralement conservées pendant une période plus courte, sauf si
              ces données sont utilisées pour renforcer la sécurité ou améliorer la fonctionnalité de Notre Service, ou
              si Nous sommes légalement obligés de conserver ces données pendant des périodes plus longues.
            </p>
            <h5 class="card-title">Divulgation de vos données personnelles</h5>

            <h6 class="card-title">Transactions commerciales</h6>
            <p class="text-muted">Si la Société est impliquée dans une fusion, une acquisition ou une vente d’actifs,
              Vos Données personnelles peuvent être transférées. Nous fournirons un avis avant que Vos Données
              personnelles ne soient transférées et ne soient soumises à une politique de confidentialité différente.
            </p>
            <h6 class="card-title">Application de la loi
            </h6>
            <p class="text-muted">Dans certaines circonstances, la Société peut être tenue de divulguer Vos Données
              personnelles si la loi l’exige ou en réponse à des demandes valables des autorités publiques (par exemple,
              un tribunal ou une agence gouvernementale).
            </p>
            <h6 class="card-title">Autres exigences légales
            </h6>
            <p class="text-muted">La Société peut divulguer vos Données personnelles si elle croit de bonne foi qu’une
              telle action est nécessaire pour :
            </p>
            <ul class="list-unstyled text-muted ml-4">
              <li>
                Se conformer à une obligation légale
              </li>
              <li>
                Protéger et défendre les droits ou la propriété de la Société
              </li>
              <li>
                Prévenir ou enquêter sur d’éventuels actes répréhensibles en rapport avec le Service
              </li>
              <li>
                Protéger la sécurité personnelle des utilisateurs du Service ou du public
              </li>
              <li>
                Se protéger contre toute responsabilité juridique.
              </li>
            </ul>
            <h6 class="card-title mt-2">Sécurité de vos données personnelles
            </h6>
            <p class="text-muted">Les renseignements personnels que nous collectons sont conservés dans un environnement
              sécurisé. Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos
              informations.
            </p>
            <p class="text-muted">Pour assurer la sécurité de vos données personnels, nous avons recours aux mesures
              suivantes :
            </p>
            <ul class="list-unstyled text-muted ml-4">
              <li>
                Protocoles de sécurité
              </li>
              <li>
                Gestion des accès
              </li>
              <li>
                Sauvegardes automatiques
              </li>
              <li>
                Pare-feu
              </li>
            </ul>
            <p class="text-muted">Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les
              dernières innovations technologiques permettant d’assurer la confidentialité de vos transactions.
              Toutefois, comme aucun mécanisme n’offre une sécurité maximale, une part de risque est toujours présente
              lorsque l’on utilise Internet pour transmettre des renseignements personnels.
            </p>
            <h4 class="card-title">Liens vers d’autres sites web
            </h4>
            <p class="text-muted">Notre Service peut contenir des liens vers d’autres sites Web qui ne sont pas
              exploités par Nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous
              vous conseillons vivement d’examiner la politique de confidentialité de chaque site que vous visitez.
            </p>
            <p class="text-muted">Nous n’avons aucun contrôle et n’assumons aucune responsabilité quant au contenu, aux
              politiques de confidentialité ou aux pratiques de tout site ou service tiers.
            </p>
            <h4 class="card-title">Modifications de la présente politique de confidentialité
            </h4>
            <p class="text-muted">Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous
              vous informerons de toute modification en publiant la nouvelle politique de confidentialité sur cette
              page.
            </p>
            <p class="text-muted">Nous vous conseillons de consulter régulièrement la présente politique de
              confidentialité pour prendre connaissance des modifications éventuelles. Les modifications apportées à la
              présente politique de confidentialité prennent effet lorsqu’elles sont publiées sur cette page.
            </p>
            <h4 class="card-title">Nous contacter
            </h4>
            <p class="text-muted">Si vous avez des questions sur cette politique de confidentialité, vous pouvez nous
              contacter :
            </p>
            <ul class="list-unstyled text-muted ml-4 mb-4">
              <li>
                Par e-mail: contact@eventizer.io

              </li>
              <li>
                Depuis la page Facebook: <a href="https://www.facebook.com/eventizerapp"
                  target="_blank">https://www.facebook.com/eventizerapp</a>
              </li>
              <li>
                Par WhatsApp: +216 92 524 188
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>