import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GenericService} from './generic.service';
import {StorageService} from './storage.service';
import {catchError} from 'rxjs/operators';
import {SubmissionRequest, Author, Submission, CommentsSubmission} from '../models/submission';
import {environment} from '../../../environments/environment';
import { QuizAbstract } from '../models/QuizAbstract';


@Injectable({
  providedIn: 'root'
})
export class SubmissionService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getSubmissionById(id: string, code: string = null) {
    const url = environment.baseUrl + '/submission/' + id + '/detail?code=' + code;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getSubmissionByIdPublic(id: string, code: string = null) {
    const url = environment.baseUrl + '/submission/' + id + '/detail/public?code=' + code;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  editSubmission(submission: Submission, authors: Author[], resourceIds: number[], addExternalFiles: boolean) {
    const url = environment.baseUrl + '/submission/' + submission.submission_id + '/edit';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.put<any>(url, {submission, authors, resourceIds, addExternalFiles}, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  editSubmissionPublic(submission: Submission, authors: Author[], resourceIds: number[], addExternalFiles: boolean, quiz: QuizAbstract[]) {
    const url = environment.baseUrl + '/submission/' + submission.submission_id + '/edit/public';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.put<any>(url, { submission, authors, resourceIds, addExternalFiles,quiz }, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getPendingSubmissions() {
    const url = environment.baseUrl + '/submission/getPendingByUser';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  addSubmission(submission: Submission, authors: Author[], resourceIds: number[]) {

    const url = environment.baseUrl + '/submission/add';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post(url, {submission, authors, resourceIds}, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  addSubmissionPublic(submission: Submission, authors: Author[], resourceIds: number[]) {

    const url = environment.baseUrl + '/submission/add/public';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post(url, {submission, authors, resourceIds}, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  acceptSubmissionBySecondaryAuthor(id: string) {
    const url = environment.baseUrl + '/submission/accept/' + id;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  rejectSubmissionBySecondaryAuthor(id: string) {
    const url = environment.baseUrl + '/submission/reject/' + id;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getSubmissionUserById(offset: number, perPage: number, search: string, congress_id: number, status: number) {
    const url = environment.baseUrl +
      `/submission/user/all/pagination?page=${offset}&perPage=${perPage}&search=${search}&congress_id=${congress_id}&status=${status}`;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllSubmissionByCongress(idCongress: number, search: string = null, status: number = null, page: number = null, perPage: number = null, offset: number = null, communication_type_id: number,theme_id: number) {
    const url = environment.baseUrl + `/submissions/congress/${idCongress}?search=${search}&status=${status}&perPage=${perPage}&offset=${offset}&communication_type_id=${communication_type_id}&theme_id=${theme_id}`;
    return this.http.get<Submission[]>(url);
  }

  addNewEtablissement(label: string, congressId: number) {
    const url = environment.baseUrl + '/congress/'+ congressId +'/etablissements/add';
    return this.http.post(url, {label})
      .pipe(catchError(this.handleErrors));
  }

  addNewService(label: string, congressId: number) {
    const url = environment.baseUrl + '/congress/'+ congressId + '/services/add';
    return this.http.post(url, {label})
      .pipe(catchError(this.handleErrors));
  }

  getServices(email, congressId) {
    const url = environment.baseUrl + '/congress/'+ congressId + '/services/by-user?email=' + email;
    return this.http.get(url).pipe(catchError(this.handleErrors));
  }

  getEtablissements(email, congressId) {
    const url = environment.baseUrl + '/congress/'+ congressId + '/etablissements/by-user?email=' + email;
    return this.http.get(url).pipe(catchError(this.handleErrors));
  }

  getPdfModePresentation(offset: number, perPage: number, congress_id: number) {
    const url = environment.baseUrl + `/submission/` + congress_id + `/pdf-mode-presentation?offset=${offset}&perPage=${perPage}`;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    }).pipe(catchError(this.handleErrors));
  }

  getTotalNumberOfPDF(congress_id: number) {
    const url = environment.baseUrl + `/submission/` + congress_id + `/number-pdf`;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    }).pipe(catchError(this.handleErrors));
  }
  addlikepdf(submission_id: number) {
    const url = environment.baseUrl + `/like-pdf?submission_id=${submission_id}`;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.put<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  correction(description) {
    const url = environment.baseUrl + '/submission/correction';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post(url, {description}, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  correctionPublic(description) {
    const url = environment.baseUrl + '/submission/correction/public';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post(url, {description}, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getQuizAbstract(congressId, submissionId) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + `/submission/` + congressId + `/quiz?submissionId=` + submissionId;
    return this.http.get(url, { headers: headers });
  }

  setQuizAbstract(congressId, submissionId, question,quiz=null) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + `/submission/` + congressId + `/quiz?submissionId=` + submissionId;
    const body = {
      question: question,
      quiz: quiz,
    };  
    return this.http.post(url, body, { headers: headers });
  }

  editSubmissionResource(resourceIds, submissionId, isPresentationFile: number = 0) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + `/submission/` + submissionId + `/edit-resource`;
    const body = {
       resourceIds,
       is_presentation_file: isPresentationFile
       }
    return this.http.post(url, body, { headers: headers });
  }
  
}
