import {StorageService} from 'src/app/shared/services/storage.service';
import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {User} from '../models/user';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable()
export class UserService extends GenericService {

  loggedUser: User;

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
    this.loggedUser = <User>storageService.read(StorageService.USER_KEY);
  }

  addUser(user: User) {
    return this.http.post(environment.baseUrl + '/user/register', user);
  }



  getAllContacts(offset: number , perPage: number, search: string , congress_id: number) {
    const url = environment.baseUrl +
    `/user/contact?offset=${offset}&perPage=${perPage}&search=${search}&eventId=${congress_id}`;
   const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
   return this.http.get<any>(url, {
     headers: headers
   })
     .pipe(catchError(this.handleErrors));
  }
  deleteContact(id: number, congress_id: number) {
    const url = environment.baseUrl + `/user/contact/${id}?congress_id=${congress_id}`;
    return this.http.delete(url)
      .pipe(catchError(this.handleErrors));
  }

  getLoggedUser(token=null) {
    const url = environment.baseUrl + '/user/me';
    const headers = this.headers.set('Authorization', 'Bearer ' + token);
    return this.http.get(url,{headers: headers});
  }


  editUser(user: User, confirmToken: string) {
    return this.http.put(environment.baseUrl + '/user/guest/update?confirmToken=' + confirmToken, user);
  }

  checkUserRights(congressId: number, accessId?: number) {
    const url = environment.baseUrl + '/congress/' + congressId +
      (accessId ? '/' + accessId + '/checkUserRights' : '/checkUserRights');
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, {headers: headers});
  }

  getAllUserAccess(congressId: number): Observable<User> {
    const url = environment.baseUrl + '/users/congress/' + congressId + '/all-access';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<User>(url, {headers: headers});
  }

  confirmInscription(code: string,user_id: number): Observable<User> {

    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl +'/users/confirmInscription/' + code + '/' + user_id;
    return this.http.get<User>(url, {headers: headers});
    
  }

  forgetPassword(email: string,congress_id:number) {
    const url = environment.baseUrl + '/users/password/reset';
    const body = {email: email,id:congress_id};
    return this.http.post(url, body);
  }


  resetPassword(user_id: number, verification_code: string, password: string) {
    const url = environment.baseUrl + '/users/password/reset/' + user_id;
    const body = {verification_code: verification_code, password: password};
    return this.http.post(url, body);
  }

  getUserById(user_id: number, verification_code: string) {
    const url = environment.baseUrl + '/users/' + user_id + '?verification_code=' + verification_code;
    return this.http.get(url);
  }

  registerToCongress(congressId: number, data) {
    const url = environment.baseUrl + `/user/congress/${congressId}/registerV2?source=frontoffice`;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post(url, data, {
      headers: headers
    });
  }

  uploadingFile(congressId: number, data) {
    const url = environment.baseUrl + `/user/congress/${congressId}/uploading_file`;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post(url, data, {
      headers: headers
    });
  }

  modifUserInfo(user: User): Observable<User> {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/user/edit/profile';
    return this.http.put<User>(url, user, {
      headers: headers
    });
  }

  getUserByEmail(email: string) {
    const url = environment.baseUrl + '/users/by-email/';
    const body = {email: email};
    return this.http.post<any>(url, body).pipe(catchError(this.handleErrors));
  }

  getUserByEmailPublic(email: string, congress_id: number = null) {
    const url = environment.baseUrl + '/users/byEmail/';
    const body = { email: email , congress_id : congress_id};
    return this.http.post<any>(url, body).pipe(catchError(this.handleErrors));
  }

  registerUser(congressId: number, data) {
    const url = environment.baseUrl + "/user/congress/" + congressId + "/register?source=frontoffice";
    return this.http.post(url, data);
  }

  isUserRegistred(congress_id: number) {
    const user = <User>this.storageService.read(StorageService.USER_KEY);
    if (user && user.user_congresses) {
      const result = user.user_congresses.filter(user_congress => user_congress.congress_id == congress_id);
      if (result.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkStandRights(congressId: number, standId: number, organizerId= null) {
    const url = organizerId ? environment.baseUrl + '/congress/' + congressId + '/' + standId + '/checkSupportRights/' + organizerId : environment.baseUrl + '/congress/' + congressId + '/' + standId + '/checkStandRights';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, {headers: headers});
  }

  checkMeetingRights(congressId: number, meetingId: number) {
    const url = environment.baseUrl + '/congress/' + congressId + '/' + meetingId + '/checkMeetingRights/';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, { headers: headers });
  }

  getOrganizers(congressId, ) {
    const url = environment.baseUrl + '/congress/' + congressId + '/getOrganizers';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, {headers: headers});
  }

  updatePaymentUser(congressId, userId, path) {
    const url = environment.baseUrl + "/users/" + userId + "/congress/" + congressId + "/update-payment";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post(url,
      {path: path},
      {headers: headers}
    );
  }
  getAllUsersByCongress(congressId: string, page: number, perPage: number, search: string) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/user/congress/" + congressId + `/listUsersPWA?page=${page}&perPage=${perPage}&search=${search}`;
    return this.http.get(url, { headers: headers });
  }

  getResponseUserInformations(userId: number,congressId: number, all: boolean = true) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/user/congress/" + congressId + "/user-details/"+userId + '?all=' + all;
    return this.http.get(url, { headers: headers });
  }

  getUserWithCongressAndMeetings(userId, congressId) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/meetings/" + congressId + "/" +userId;
    return this.http.get(url, { headers: headers });
  }

  registerUserToCongress(congressId: number, user: User, returnUser: number = 0, formId = null, currency_id = null) {
    const url = environment.baseUrl + "/user/congress/" + congressId + "/register" + "?returnUser=" + returnUser + "&formId=" + formId + (currency_id ? '&currency_id=' + currency_id : '');
    return this.http.post(url, user);
  }

  getRegistrationData(congressId: number, formerDataId: string) {
    const url = environment.baseUrl + '/' + congressId + "/regitration-data?cachedId=" + formerDataId;
    return this.http.get(url);
  }
  
  addRegistrationData(congressId: number, user: User) {
    const url = environment.baseUrl + '/' + congressId + "/regitration-data";
    return this.http.post(url, {user: user});
  }
  
  getUserByEmailAndQrCode(qrCode, email) {
    const url = environment.baseUrl + '/users/user-by-qr-code?email=' + email + '&qr_code=' + qrCode;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, { headers: headers });
  }

  getUserByIdAndCongresssId(congressId, userId, userCode) {
    const url = environment.baseUrl + '/user/' + userId + '/my-data/' + congressId + '?verificationCode=' + userCode;
    return this.http.get(url);
  }

  
  editerUser(congressId: number, user: User) {
    const url = environment.baseUrl + "/user/congress/" + congressId + "/edit-registration/" + user.user_id;
    return this.http.put(url, user);
  }

  getUserByIdWithResponses(userId: number,congressId: number) {
    const url = environment.baseUrl + "/users/userdetail/" + userId + "/" + congressId;
    return this.http.get(url);
  }

}
