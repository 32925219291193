import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import {FormInputValue} from '../../models/formInput';

@Component({
  selector: 'app-ng-generic',
  templateUrl: './ng-generic.component.html',
  styleUrls: ['./ng-generic.component.css']
})
export class NgGenericComponent implements OnInit, OnChanges {
  @Input() type;
  @Input() options: FormInputValue[];
  @Input() name;
  @Input() label;
  @Input() form_input_id: number;
  @Input() toShow: boolean = true;
  @Input() values = null;
  @Input() required = false;
  @Input() submitted = false;
  @Output() answer = new EventEmitter();
  previousValues = null;
  checkListValues = null;

  constructor() {
  }

  ngOnInit(): void {
    if (this.type === 'checklist') {
      this.checkListValues = [];
        for (const response of this.options) {
          const selected = this.values.length > 0 && this.values.includes(response.form_input_value_id) ? 1 : 0;
          this.checkListValues.push({ form_input_value_id: response.form_input_value_id, selected: selected });
        }
    }
  }

  ngOnChanges() {
    if (!this.toShow) {
      this.values = (this.type === 'checklist' || this.type === 'multiselect') ? [] : null;
      this.previousValues = (this.type === 'checklist' || this.type === 'multiselect') ? [] : null;
      this.answer.emit({ values: this.values, previousValues: this.previousValues });
    }
  }

  onValueChange() {
    setTimeout(() => {
    }, 100);
    const values = [];
    if (this.type === 'checklist') {
      this.validateCheckBox(values);
    } else if (this.type === 'multiselect' || this.type === 'select') {
      this.validateSelect();
    } else {
      setTimeout(() => {
        // Case of radio box
        this.answer.emit({ values: this.values, previousValues: this.previousValues });
        this.previousValues = this.values;
      }, 100);
    }
  }

  validateCheckBox(values) {
    for (const rd of this.checkListValues) {
      if (rd.selected === 1) {
        values.push(rd.form_input_value_id);
      }
    }
    this.answer.emit({ values: values, previousValues: this.previousValues });    
    this.previousValues = [...values];
  }

  validateSelect() {
    setTimeout(() => {
      if (this.values.length === 0) {
        this.values = null;
      }
      this.answer.emit({ values: this.values, previousValues: this.previousValues });
      if (typeof this.values === 'string' || this.values == null) {
        this.previousValues = this.values;
      } else {
        this.previousValues = [...this.values];
      }
    }, 100);
  }

}
