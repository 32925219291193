import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';
import { catchError } from 'rxjs/operators';
import { Submission } from '../models/submission';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Theme } from '../models/theme';
import { Congress } from '../models/congress';
import { Organization } from '../models/organization';
import { FMenu } from '../models/FMenu';
import { Gender } from '../models/Gender.model';
import { FormInput } from '../models/formInput';

@Injectable({
  providedIn: 'root'
})
export class CongressService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }


  getOrganizationsByCongress(congressId: string, logo: boolean): Observable<Array<Organization>> {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + `/organismes?logo=${logo}`;
    return this.http.get(url, {
      headers: headers
    }) as Observable<Array<Organization>>;
  }

  getAllCongresses() {
    const url = environment.baseUrl + '/user/congress/getMinimalCongress';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllCongressesPaged(page: number) {
    const url = environment.baseUrl + '/congress/pages?page=' + page + '&size=5';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllCongressesPagedByKeyword(keyword: string, page: number) {
    const url = environment.baseUrl + '/congress/search?keyword=' + keyword + '&page=' + page + '&size=5';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllCongressesPublic(search: string, page: number, perPage: number, status = '', startDate = '', endDate = '',minPrice='',maxPrice='',type) {
    const url = environment.baseUrl + `/congress/list/pagination?page=${page}&perPage=${perPage}&search=${search}&status=${status}&startDate=${startDate}&endDate=${endDate}&minPrice=${minPrice}&maxPrice=${maxPrice}&type=${type}`;
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getMyCongresses(search: string, offset: number, perPage: number, startDate: string, endDate: string, state: string) {
    const url = environment.baseUrl + `/user/me/events?
    offset=${offset}&perPage=${perPage}&search=${search}&startDate=${startDate}&endDate=${endDate}&status=${state}`;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllCongressesLight() {
    const url = environment.baseUrl + '/congress/getAllLight';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }


  getCongressById(id: string) {
    const url = environment.baseUrl + '/congress/' + id;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getCongressDetailsById(id: string) {
    const url = environment.baseUrl + '/congress/' + id + '/details';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
      
  }


  getThemesByCongressId(congressId: string): Observable<Theme[]> {
    const url = environment.baseUrl + '/theme/congress/' + congressId;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<Theme[]>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getThemesByCongressIdPublic(congressId: string): Observable<Theme[]> {
    const url = environment.baseUrl + '/theme/congress/' + congressId + '/public';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<Theme[]>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllSubmissions() {
    const url = environment.baseUrl + '/submission/getByUser';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getSubmissionByCongressId(id: string): Observable<Array<Submission>> {
    const url = environment.baseUrl + '/submission/getByUserAndCongressId/' + id;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<Array<Submission>>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getMinCongressData() {
    const url = environment.baseUrl + '/congress/minCongressData';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));

    return this.http.get(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  deleteSubmission(id: string) {
    const url = environment.baseUrl + '/submission/' + id;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));

    return this.http.delete(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getCongressMinById(congressId: number) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + '/mins';

    return this.http.get(url, {
      headers: headers
    }) as Observable<Congress>;
  }
  
  getAbstractBook(path: string) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/user-abstract-book/' + path + '/downloadAbstractBook';

    return this.http.get(url, {
      headers: headers,
      responseType: 'blob'
    });
  }

  makeUserPresent(congressId, accessId, type) {
    const url = environment.baseUrl + '/user/access/presence';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post<any>(url, {
      isPresent: 1,
      type: type,
      congressId: congressId,
      accessId: accessId
    }, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  setCurrentParticipant(congressId: string, accessId: number, numberOfParticipants) {
    const url = environment.baseUrl + '/user/congress/' + congressId + '/set-current-participant';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post<any>(url, {
      accessId: accessId,
      nbParticipants: numberOfParticipants
    }, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }
  getConfigLandingPageById(congress_id: string) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congress_id + "/landing-page/get-config";
    return this.http.get(url, {
      headers: headers
    });
  }

  getLandingPageSpeakers(congress_id: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congress_id + "/landing-page/get-speakers";
    return this.http.get(url, { headers: headers });
  }

  getLandingPageOganizers(congress_id: number) {
    const url = environment.baseUrl + "/congress/" + congress_id + "/landing-page/get-organizers";
    return this.http.get(url);
  }

  getSponsorsByCongress(congressId: number, isSponsor: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congressId + "/sponsor?isSponsor=" + isSponsor;
    return this.http.get(url, {
      headers: headers
    })
  }

  saveTracking(action: string, type: string, name: string, date_entry: string, date_leave: string, userId: number, duration: number, congressId: number) {
    const url = environment.elasticProxyUrl + '/track/eventizer/congress?accessToken=c5qxgyEa7qm6g9AXWf4iWjxsSBKFZf';
    const body = {
      "action": action,
      "type": type,
      "channel_name": name,
      "date_entry": date_entry,
      "date_leave": date_leave,
      "user_id": userId,
      "comment": "",
      "duration": duration,
      "env": environment.environment,
      "congress_id": congressId
    }
   return this.http.post(url, body);
  }
  
  getAllUsersByCongress(congressId,page,perPage,search,  filterBy: [] = null,all: boolean = true ,isSelectCountry = null) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/user/congress/" + congressId + `/listUsers?page=${page}&perPage=${perPage}&search=${search}&filterBy=${filterBy}&all=${all}&isSelectCountry=${isSelectCountry}`;
    return this.http.get(url, {
      headers: headers,
    });
  }

  getGenericFmenus(congress_id: string) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/admin/me/congress/" + congress_id + "/getfmenus";
    return this.http.get<FMenu[]>(url, {
      headers: headers
    });
  }

  getGendersByCongressId(congressId:number) {
    const url = environment.baseUrl + "/congress/" + congressId + "/gender/cached";
    return this.http.get<Gender[]>(url);
  }
  
  getFilterValues(congressId: string) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/user/congress/' + congressId + '/filter-values';
    return this.http.get<FormInput>(url, { headers: headers }).pipe(catchError(this.handleErrors));
  }

  getAllCompanies(congressId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/companies/'+ congressId + '/get';
    return this.http.get(url, { headers: headers });
  }

  getAllSponsorPacks(congressId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/sponsor-pack/'+ congressId + '/get';
    return this.http.get(url, {headers: headers});
  }


  getPhoto(congress_id: number, page, perPage,groupSelected,tagsSelected) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congress_id + `/gallery/get/photos?page=${page}&perPage=${perPage}&groupSelected=${groupSelected}&tagsSelected=${tagsSelected}`;
    return this.http.get(url, { headers: headers });
  }

  getTagsGroup(congress_id: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congress_id + "/gallery/get/tag-groupe";
    return this.http.get(url, { headers: headers });
  }

  getTags(congress_id: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congress_id + "/gallery/get/tag";
    return this.http.get(url, { headers: headers });
  }

  downloadFiles(congress_id: number,groupSelected,tagsSelected) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congress_id + `/gallery/download-files?groupSelected=${groupSelected}&tagsSelected=${tagsSelected}`;
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }

  downloadFile(congress_id: number,path : string) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congress_id + `/gallery/get/ressources?path=${path}`;
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }

  getAllCountries(congressId= null) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/countries?congressId=" + congressId;
    return this.http.get(url,{ headers: headers });
}
getStatAllCountries(congressId= null) {
  const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
  const url = environment.baseUrl + "/congress/" + congressId + "/stats/countries?all=true";
  return this.http.get(url,{ headers: headers });
}

getSeoByPage(congressId:number,page:string)
  {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/seo/' + congressId + '/' + page;
    return this.http.get(url,{ headers: headers });
  }

  getGendersByCongressIdForUsers(congressId: number) {
    const url = environment.baseUrl + "/congress/" + congressId + "/gender/cached";
    return this.http.get(url);
  }

  getFormInputByFormId(formId: number) {
    const url = environment.baseUrl + "/form/" + formId;
    return this.http.get(url);
  }

  getFormById(formId: number) {
    return this.http.get(environment.baseUrl + '/congress/form/group/' + formId + '/get-form-by-id-front');
  }

  getOnePromo(code: string, congressId: number) {
    const url = environment.baseUrl + "/promo/" + congressId +  "?code=" + code;
    return this.http.get(url);
  }

  getMinCongressPublic(congressId) {
    const url = environment.baseUrl + '/congress/' + congressId + '/mindetails';
    return this.http.get(url);
  }

  getCurrentDiscount(congressId: number, allTransaction = '', onlinePayment = '') {
    const url = environment.baseUrl + "/discount/" + congressId + "/current-discount?all_transaction=" + allTransaction + "&online_payment=" + onlinePayment;
    return this.http.get(url);
  }

  getConfigCongressById(congressId: number) {
    const url = environment.baseUrl + "/congress/" + congressId + '/configs';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, {
      headers: headers
    });
  }

  getFormInputByCongressPublic(congressId: number) {
    return this.http.get(environment.baseUrl + '/congress/form/group/' + congressId + '/get-form-public');
  }

}
