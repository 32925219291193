import {User} from './user';
import {Congress} from './congress';
import {File} from './file';
import { Theme } from './theme';
import { QuizAbstract } from './QuizAbstract';

/*export class Submission {
  congress: Congress;
  description: string;
  form: string;
  documents: File [];
  theme: string;
  title: string;
  author: User;
  authors: User[];
  acceptedAuthors: User[];
  rejectedAuthors: User[];
  corrector: User;
  id: string;
  status: number;
  auteurNames: string[];
  abstract: AbstractElement[] = [];

  static toSubmissionRequest(submission: Submission): SubmissionRequest {
    const s = new SubmissionRequest();
    s.id = submission.id;
    s.title = submission.title;
    s.theme = submission.theme;
    s.form = submission.form;
    s.auteurNames = submission.auteurNames;
    s.description = submission.description;
    s.congress = submission.congress.id;
    s.abstract = submission.abstract;
    if (submission.documents) {
      s.documents = [];
      submission.documents.forEach(document => {
        if (document && document.id) {
          s.documents.push(document.id);
        }
      });
    }
    return (s);
  }
}*/
export class Submission {
  submission_id: string;
  title: string;
  type: string;
  communication_type_id: string;
  description = '';
  documents: File [];
  global_note: number;
  status: number;
  congress_id: string;
  theme_id: number;
  abstract: AbstractElement[] = [];
  congress: Congress;
  author: Author | User;
  authors: Author[] = [] ;
  auteurNames: string[];
  authorNames: string ;
  limit_date: string;
  created_at: string;
  code: number;
  resources: Ressource [] = [];
  fileType: string;
  comments: CommentsSubmission[] = [];
  key_words:string;
  theme: Theme;
  user: User;
  quiz_abstract: QuizAbstract[] = [];
}

export class Ressource {
  submission_id: string;
  resource_submission_id: string;
  path: string;
  resource_id: string;
}

export class Author {
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  rank: number;
  services: any = null;
  etablissement_id: string;
  customService: string;
  customEstablishment: string;
  profile_pic: string;
  otherService:string = '';
  otherEstablishment: string = '';
  author_services : any []=[];
  etablissment: any = null;
  serviceLabel: any;
}

export class SubmissionRequest {
  congress: string;
  description: string;
  form: string;
  documents: string [];
  theme: string;
  title: string;
  id: string;
  auteurNames: string[] = [];
  abstract: AbstractElement[] = [];
}

export class AbstractElement {
  static sequence = 0;
  id: string;
  title: string;
  value: string;

  constructor(title: string) {
    this.title = title;
    this.value = '';
    this.id = String(AbstractElement.sequence++);
  }

}
export class CommentsSubmission {
  submission_comments_id: number;
  description: string;
  submission_id: number;
}
