import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Submission } from '../models/submission';
import { SubmissionService } from '../services/submission.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { CongressService } from '../services/congress.service';
import { Utils } from '../utils';
import { TranslateService } from '@ngx-translate/core';
import { Congress } from '../models/congress';
import { SharedService } from '../services/shared.service';

declare var jQuery: any;

@Component({
  selector: 'app-item-submission',
  templateUrl: './item-submission.component.html',
  styleUrls: ['./item-submission.component.css']
})
export class ItemSubmissionComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  submissions: Submission[] = [];
  @Input() communicationOrale: Submission[] = [];
  @Input() communicationAffiche: Submission[] = [];
  @Input() allSubmissions: Submission[] = [];
  @Input() totalItems: number;
  @Input() isExternal: boolean = false;
  @Input() filter: string = "";
  @Input() congress: Congress = new Congress();
  @Output() pageChangement: EventEmitter<{ page: number, type: string }> = new EventEmitter();

  imgUrl = `assets\img\abstract-images`;
  congress_id: number;
  p = 1;
  id: string;
  isAllSubmissions = false;
  pathResources = environment.filesUrl;
  busy: Subscription;
  uploadedFile;
  selectedSubmission: Submission;
  resourceIds: any = [];
  isXs = false;

  constructor(private submissionService: SubmissionService,
    private route: ActivatedRoute,
    private congressService: CongressService,
    private translate: TranslateService,
    private router: Router,
    private sharedService: SharedService
  ) {
    this.route.params.subscribe((params) => {
      this.congress_id = params['congress_id'];
    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isXs = window.innerWidth < 576;
  }

  ngOnInit(): void {
    this.isXs = window.innerWidth < 576;
    if (this.allSubmissions.length !== 0) {
      this.submissions = this.allSubmissions;
      this.isAllSubmissions = true;
      this.id = 'all';
    } else {
      this.fillSubmissionTable();
    }

    this.applyFilter();
  }

  applyFilter(): void {
    if (this.filter === "true") {
      this.submissions = this.submissions.filter(submission => submission.status == 4);
    }
  }

  showModal1(i: number) {
    jQuery('#id' + i).modal('show');
  }

  showModal2(i: number) {
    jQuery('#videoId' + i).modal('show');
  }

  ngOnChanges() {
    this.submissions = [];
    this.submissions = this.allSubmissions;
    this.applyFilter();
    this.fillSubmissionTable();
  }

  fillSubmissionTable() {
    if (this.communicationOrale.length !== 0) {
      this.submissions = this.communicationOrale;
      this.id = 'orale';
    }

    if (this.communicationAffiche.length !== 0) {
      this.submissions = this.communicationAffiche;
      this.id = 'affiche';
    }
  }

  loadNextSubmissions(event, type) {
    this.p = event;
    this.pageChangement.emit({ page: this.p, type: type });
  }

  deleteSubmission(id_submission, index) {
    const baseContext = this;
    const submission: Submission = this.submissions.find(submission => submission.submission_id = id_submission);
    if (submission.status) {
      Utils.swalMessage('SWAL.SubmissionEvaluated', 'SWAL.SUBNONDETELED', 'warning', this.translate);
      return;
    }

    const params = {
      buttons: true,
      dangerMode: true
    }

    Utils.swalMessageWithCallback('SWAL.Sure', 'SWAL.OnceDeleted', 'warning', params, this.translate, function (isConfirm) {
      if (isConfirm) {
        baseContext.busy = baseContext.congressService.deleteSubmission(submission.submission_id).subscribe(data => {
          baseContext.submissions.splice(index, 1);
          Utils.swalMessage('SWAL.DELETED', 'SWAL.SUBDETELED', 'success', baseContext.translate);

        }, error => {
          Utils.swalMessage('SWAL.NONDELETED', 'SWAL.SUBNONDETELED', 'warning', baseContext.translate);

        });
      }
    }
    );
  }

  downloadFile(path) {
    window.open(this.pathResources + path);
  }

  truncateTitle(title: string): string {
    const maxLength = 20;
    if (title.length > maxLength) {
      const words = title.split(' ');
      let truncatedTitle = '';
      words.forEach(word => {
        if (word.length > maxLength) {
          const regex = new RegExp(`.{1,${maxLength}}`, 'g');
          const brokenWord = word.match(regex)?.join('\n') || word;
          truncatedTitle += brokenWord + ' ';
        } else {
          truncatedTitle += word + ' ';
        }
      });

      return truncatedTitle.trim();
    }

    return title;
  }

  openSubmission(submission: any) {
    const baseContext = this;
    if (this.isAllSubmissions && this.isExternal) {
      if (submission.status === 0 || submission.status === 2 || submission.status === 6) {
        if (Utils.compareDateWithDateNow(this.congress.config_submission.end_submission_date)) {
          Utils.swalMessage('SHARED.ERROR', 'SUBMISSION.DateSubmissionAbstractPassed', 'warning', this.translate);
          return;
        }

        this.router.navigate(['../edit-abstract', submission.submission_id], { relativeTo: this.route });
      }

      if (submission.status == 4 || submission.status == 5) {
        if (this.congress.config_submission.is_quiz_mandatory == 1) {
          if (submission.quiz_abstract.length == 0) {
            Utils.swalMessage('SWAL.CANCELED', 'QUIZ.ConfigQuizNedded', 'warning', baseContext.translate);
            return;
          }
        }

        this.fileInput.nativeElement.click();
        this.selectedSubmission = submission;
      }
    }
  }

  addFile($event) {
    this.uploadedFile = $event.target.files[0] as File;
    this.busy = this.sharedService.uploadFile(this.uploadedFile).subscribe({
      next: (res: any) => {
        this.resourceIds.push(res.resource.resource_id);
        this.submissionService.editSubmissionResource(this.resourceIds, this.selectedSubmission.submission_id, 1).subscribe((data: any) => {
          Utils.swalMessage('SHARED.SUCCESS', 'SHARED.PHOTO_ADDED_SUCCESS', 'success', this.translate);
          this.selectedSubmission.status = data.submission.status;
          this.resourceIds = [];
        });
      },
      error: (rep: any) => {
        if (rep.status === 401) {
          Utils.swalMessage('Error', 'BinFileError', 'error', this.translate);
        }
        else {
          Utils.swalMessage('Error', 'ErrorUpload', 'error', this.translate);
        }
      },
    });
  }
}
